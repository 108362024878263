/* React Big Calendar Styling - remove time slots */
.rbc-month-view {
    font-size: 12px;
}
.rbc-time-view {
    .rbc-label {
        display: none;
    }
    .rbc-time-header {
        /* min-height: 100vh; */
        border-bottom: 1px solid #ddd;
    }
    .rbc-allday-cell {

    }
    .rbc-time-content {
        display: none;
    }
    
}
.rbc-off-range-bg{
    background: inherit!important;
}

.rbc-day-bg + .rbc-off-range-bg {
        background: inherit!important;
}

.rbc-today {

}

.rbc-addons-dnd-resize-ew-anchor .rbc-addons-dnd-resize-ew-icon {
    height: 100%!important;
}


/* For the week and day view */
/* .rbc-calendar {
    position: initial!important;
    .rbc-time-header {
        .rbc-time-header-content {
            position: relative!important;
                top: 0!important;
                left: 0 !important;
        }
    }
    .rbc-time-view {
        position: initial!important;
        .rbc-time-header {
            .rbc-time-header-content {
                .rbc-allday-cell {
                    display: none!important;
                }
            }
        }
        .rbc-time-content {
            position: initial!important;
            margin-left: 79.3875px!important;
    
            .rbc-time-gutter.rbc-time-column {
                position: absolute!important;
                top: 0!important;
                left: 0 !important;
                margin-top: 21.600px!important;
                z-index: 10!important;
                background-color: aqua!important;

                .rbc-day-slot {
                    .rbc-timeslot-group {
                        max-height: 40px!important;
                        .rbc-timeslot {
                            height: 39.200px!important;
                        }
                    }
                }
            }

            .rbc-day-slot.rbc-time-column:first-child {
                margin-left: 79.3875px!important;
            }
            
            
        }
        
    }
} */

/* .rbc-calendar {
    .rbc-time-view {
        .rbc-time-header {
            .rbc-label.rbc-time-header-gutter {
                display: none!important;
            }
            .rbc-time-header-content {
                .rbc-allday-cell {
                    display: none!important;
                }
            }
        }
        .rbc-time-content {
            .rbc-time-gutter.rbc-time-column {
             display: none!important; 
            }

            
            
        }
        
    }
}  */

